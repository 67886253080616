'use client';

import { useUser } from '@auth0/nextjs-auth0/client';
import CircularProgress from '@mui/material/CircularProgress';
import { useRouter } from 'next/navigation';
import Script from 'next/script';
import { useEffect, useState } from 'react';

// Declar Tally from the global scope
declare const Tally: {
  loadEmbeds: () => void;
};

export default function Home() {
  const { user, isLoading } = useUser();
  const router = useRouter();
  const [isIframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    if (!isLoading && !user) {
      router.push('/api/auth/login');
    }
  }, [isLoading, user, router]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const handleFormLoaded = () => {
    Tally?.loadEmbeds();
    setIframeLoaded(true);
  };

  return (
    <div
      style={{
        position: 'relative',
        height: '100%',
        width: '80%',
        margin: '0 auto',
      }}
    >
      {!isIframeLoaded && (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        />
      )}
      <iframe
        data-tally-src="https://tally.so/embed/3xN99J?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
        loading="lazy"
        width="100%"
        height="100%"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
        title="Business Information Form"
      />
      <Script
        src="https://tally.so/widgets/embed.js"
        onLoad={() => handleFormLoaded()}
      />
    </div>
  );
}
